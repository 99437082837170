$.fn.lui_tippy = function (attributes, onShow, onHide) {
    $(this).each(function(){
        let elm = $(this);
        let data = elm.data(attributes).replace(/\s/g,"").split(",");
        let content;
        let trigger = "mouseenter focus";
        let placement = "top";
        let arrow = true;
        let cursor = false;
        let distance = 0;
        let interactive = true;
        let template;

        if (typeof data[1] !== "undefined") {
            template = $('#' + data[1]);

            content = template.html();
        } else {
            content = `
                <div class="part_ui_dropdown">
                    <div class="wrp_ui_body">
                        ${elm.attr("aria-label")}
                    </div>
                </div>
            `;
            interactive = false;
        }

        if (typeof data[0] !== "undefined") {
            if (data[0].includes("dropdown")) {
                trigger = "click";
                if (data[0].includes("dropdown-")) {
                    placement = data[0].replace("dropdown-", "").replace(/\d+/,"");
                    if (data[0].match(/\d+/)) {
                        distance = parseInt(data[0].match(/\d+/)[0]);
                    }
                }
            } else if (data[0].includes("tooltip")) {
                if (data[0].includes("tooltip-")) {
                    placement = data[0].replace("tooltip-", "");
                    // arrow = false;
                    interactive = false;
                }
                distance = 10;
            } else if (data[0].includes("cursor-")) {
                placement = data[0].replace("cursor-", "")
                cursor = "horizontal";
                interactive = false;
            } else {
                placement = data[0];
            }
        }

        if (typeof data[2] !== "undefined") {
            content = content.replace(/{this}/g,data[2]);
            content = content.replace(/%7Bthis%7D/g,data[2]);
        }

        if (content.match("{this}") && typeof data[2] === "undefined" && elm.attr("aria-label")) {
            content = content.replace(/{this}/g,'<hr>' + elm.attr("aria-label"));
        }

        content = content.replace(/{this}/g,'');

        tippy(elm[0], {
            content: content,
            placement: placement,
            theme: 'light-border',
            trigger: trigger,
            interactive: interactive,
            arrow: arrow,
            arrowType: 'round',
            animation: "scale",
            followCursor: cursor,
            flip: true,
            inertia: true,
            distance: distance,
            onShow(instance) {
                if(elm.closest("#layout_nav").length && elm.closest("#layout_nav").hasClass("is--compact")) {
                    setTimeout(()=>{
                        let transform = instance.popper.style.transform.split("(")[1].replace(")","").replaceAll(" ","").split(",");
                        instance.popper.style.transform = `translate3D(20px, ${transform[1]}, ${transform[2]})`;
                    },100);
                }
                $(instance.popper).children(".tippy-tooltip").attr("data-name", data[1]);

                if ($(instance.popperChildren.content).find("[data-lib-scrollbars]").length) {
                    lui.lib["lib_scrollbars"]("lib_scrollbars",$(instance.popperChildren.content).find("[data-lib-scrollbars]"));
                }

                $(instance.popperChildren.content).find(`input[type="radio"]`).on("change", function(e) {
                    $(`[data-lib-tippy*="${data[1]}"]`).find("span:not(.icon-r)").text($(e.target).next("span").text());
                    instance.hide({ duration: 0 });
                });

                if (onShow) {
                    onShow(instance);
                }
            },
            onHide(instance) {
                if (onHide) {
                    onHide(instance);
                }
            }
        });
    });
};