doc.on("click", `[data-doc-toggle="aside"]`, function() {
    let elm = $(this);
    let options = elm.data("options");

    $.ajax({
        dataType: "json",
        url: options["snippet"]
    }).done(function(data) {
        $(lui.refs.layout_aside).html("").append(data["snippet"]);
        luiLoad({"name": ["comp", "lib", "part"], "selector": "#layout_aside"});

        if (!$(lui.refs.layout_aside).hasClass("state--active")) {
            $(lui.refs.layout_aside).addClass("state--active");
            body.addClass("is--overlay");
        } else {
            $(lui.refs.layout_aside).removeClass("state--active");
            body.removeClass("is--overlay");
        }
    });
});

html.on("click.aside", function(e) {
    if ($(lui.refs.layout_aside).length && $(lui.refs.layout_aside).hasClass("state--active")) {
        if ($(e.target).closest(lui.refs.layout_main).length) {
            $(lui.refs.layout_aside).removeClass("state--active");
            body.removeClass("is--overlay");
        }
    }
});

doc.on('click', '[data-doc-click="toggleNotificationRead"]', function(e) {
    e.preventDefault();
    naja.makeRequest('POST', $(this).data('url')).then(() => {
        swup.loadPage({
            url: $(this)[0].href,
            method: 'GET'
        });
    });
});