(function(){
    let timeoutToolbar;

    $.lui("layout", lui.refs.layout_toolbar, function(selector) {
        let options;
        let delay = 0;

        if (typeof selector.data("options") !== "undefined") {
            options = selector.data("options");
        }

        if (typeof swup !== "undefined" && typeof swup.cache["getCurrentPage"]() !== "undefined") {
            let html = $(swup.cache["getCurrentPage"]().originalContent).find(lui.refs.layout_toolbar);

            if (typeof html.data("options") !== "undefined") {
                options = html.data("options");
                delay = options["delay"];
            }

            if (typeof html.data("options") !== "undefined" && typeof options["delay"] !== "undefined") {
                setTimeout(function(){
                    $(lui.refs.layout_toolbar).replaceWith(html);
                    selector = $(lui.refs.layout_toolbar);
                },delay);
            } else {
                $(lui.refs.layout_toolbar).replaceWith(html);
                selector = $(lui.refs.layout_toolbar);
            }
        }

        if (typeof selector.data("options") !== "undefined" && options["show"]) {
            setTimeout(function(){
                $(lui.refs.layout_navbar).addClass("state--hidden");

                selector.addClass("state--active");
                body.addClass("is--toolbar-active");
                clearTimeout(timeoutToolbar);
                timeoutToolbar = setTimeout(function(){
                    if (typeof Capacitor !== "undefined" && typeof Capacitor.Plugins.NavigationBar !== "undefined") {
                        let backgroundColor;

                        if (selector.data("options")["backgroundColor"] === "") {
                            backgroundColor = "#ffffff"
                        } else {
                            backgroundColor = selector.data("options")["backgroundColor"]
                        }
                        
                        Capacitor.Plugins.NavigationBar.setBackgroundColor({
                            "color": backgroundColor
                        });
                    }
                },150);
            },10);
        } else {
            $(lui.refs.layout_navbar).removeClass("state--hidden");
            selector.removeClass("state--active");
            body.removeClass("is--toolbar-active");
            clearTimeout(timeoutToolbar);
            timeoutToolbar = setTimeout(function() {
                if (typeof Capacitor !== "undefined" && typeof Capacitor.Plugins.NavigationBar !== "undefined") {
                    Capacitor.Plugins.NavigationBar.setBackgroundColor({color: '#ffffff'});
                }
            },150);
        }

        setTimeout(function() {
            luiLoad({"name": ["comp", "part", "lib"], "selector": lui.refs.layout_toolbar});

            selector.on("click", `[data-click="close"]`, function () {
                selector.removeClass("state--active");
                $(lui.refs.layout_navbar).removeClass("state--hidden");
                body.removeClass("is--toolbar-active");
                $(`[data-doc-click="toggleAvailability"]`).removeClass("state--active");
                selector.find(`input[name="ids"]`).val(JSON.stringify([]));

                clearTimeout(timeoutToolbar);
                timeoutToolbar = setTimeout(function(){
                    if (typeof Capacitor !== "undefined" && typeof Capacitor.Plugins.NavigationBar !== "undefined") {
                        Capacitor.Plugins.NavigationBar.setBackgroundColor({color: '#ffffff'});
                    }
                },150);
            });
        },delay);
    });

    doc.on("click", `[data-doc-click="toggleAvailability"]`, function() {
        let elm = $(this);
        let id = elm.data("id");
        let toolbar = $(lui.refs.layout_toolbar);
        let input = toolbar.find(`input[name="ids"]`);
        let values = input.val();

        if (!values) {
            values = []
        } else {
            values = JSON.parse(values);
        }

        if (!values.includes(id)) {
            values.push(id);
            elm.addClass("state--active");
        } else {
            values = values.filter(item => item !== id);
            elm.removeClass("state--active");
        }

        input.val(JSON.stringify(values));

        if (values.length > 0) {
            if (!toolbar.hasClass("state--active")) {
                toolbar.addClass("state--active");
                body.addClass("is--toolbar-active");
                $(lui.refs.layout_navbar).addClass("state--hidden");

                clearTimeout(timeoutToolbar);
                timeoutToolbar = setTimeout(function(){
                    if (typeof Capacitor !== "undefined" && typeof Capacitor.Plugins.NavigationBar !== "undefined") {
                        Capacitor.Plugins.NavigationBar.setBackgroundColor({color: '#292a40'});
                    }
                },150);
            }

            toolbar.find(`[data-ref="count"]`).text(values.length);
        } else {
            toolbar.removeClass("state--active");
            body.removeClass("is--toolbar-active");
            $(lui.refs.layout_navbar).removeClass("state--hidden");

            clearTimeout(timeoutToolbar);
            timeoutToolbar = setTimeout(function(){
                if (typeof Capacitor !== "undefined" && typeof Capacitor.Plugins.NavigationBar !== "undefined") {
                    Capacitor.Plugins.NavigationBar.setBackgroundColor({color: '#ffffff'});
                }
            },150);
        }
    });

    doc.on("click", `[data-doc-click="submitForm"]`, function(e) {
        e.preventDefault();
        $('[data-doc-ref="submitForm"]').find('[name="_submit"]').click();
    });

    doc.on("click", `[data-doc-click="resetForm"]`, function(e) {
        e.preventDefault();
        $('[data-doc-ref="submitForm"]').find('[name="_reset"]').click();
    });
})();
