lui_dialog.init(lui_dialog_callback);

$.lui("lib_ripple", ".part_ui_btn, .part_ui_btn_circle", function(selector) {
    selector.lui_ripple();
});

$.lui("lib_range", "[data-lib-range]", function(selector){
    let refs = {
        slider: `[data-lib-range-slider]`,
        min: `[data-lib-range-input="min"]`,
        max: `[data-lib-range-input="max"]`,
        value: `[data-lib-range-value]`
    };

    selector.each(function () {
        let elm = $(this);
        $.importScript(cdnjs.autoNumeric, function () {
            $.importScript(cdnjs.noui, function () {
                let unit = elm.closest(selector).find(refs.value).data("lib-range-value");

                elm.lib_noui({
                    slider: elm.find(refs.slider),
                    elm_min: elm.find(refs.min),
                    elm_max: elm.find(refs.max),
                    elm_min_hidden: elm.find(refs.min).next(),
                    elm_max_hidden: elm.find(refs.max).next(),
                    unit: elm.data("lib-range-unit")
                });

                elm.on("change", refs.min, function () {
                    let min = $(this).val();
                    let max = elm.find(refs.max).val();
                    elm.closest(selector).find(refs.value).text(`${min.replace(",-", "")} ${typeof unit !== "undefined" ? unit: ""} - ${max.replace(",-", "")} ${typeof unit !== "undefined" ? unit: ""}`);
                });
                elm.on("change", refs.max, function () {
                    let min = elm.find(refs.min).val();
                    let max = $(this).val();
                    elm.closest(selector).find(refs.value).text(`${min.replace(",-", "")} ${typeof unit !== "undefined" ? unit: ""} - ${max.replace(",-", "")} ${typeof unit !== "undefined" ? unit: ""}`);
                });
            });
        });
    });
});

$.lui("lib_autosize", "[data-lib-autosize]", function(selector) {
    $.importScript(cdnjs.autosize, function(){
        autosize(selector);

        selector.keydown(function(e){
            if (e.keyCode === 13 && !e.shiftKey) {
                naja.uiHandler.submitForm(selector.closest("form")[0]);
                e.preventDefault();
            }
        });
    });
});

$.lui("lib_tippy", "[data-lib-tippy]", function(selector) {
    selector.lui_tippy("lib-tippy");
});

$.lui("lib_flickity", "[data-lib-flickity]", function(selector) {
    selector.lib_flickity();
});

$.lui("lib_sortable", "[data-lib-sortable]", function(selector) {
    selector.lib_sortable();
});

$.lui("lib_datepicker", "[data-lib-datepicker]", function(selector) {
    $.importScript(cdnjs.datepicker_lang.replace(".cs.", "." + lang + "."), function () {
        selector.lui_datepicker();
    });
});

$.lui("lib_select", ".part_ui_select", function(selector) {
    selector.lui_select();
});

$.lui("lib_gallery", "[data-lib-fancybox]", function(selector) {
    bodyLoaded(function(){
        if ($(selector).find(".lib--fancybox-item").length) {
            $.importStyle(cdnjs.fancybox_css);
            $.importScript(cdnjs.fancybox, function(){
                $(selector).each(function(){
                    let id = $(this).data("lib-fancybox");
                    $(this).fancybox({
                        selector: `[data-lib-fancybox="${id}"] .lib--fancybox-item`,
                        transitionEffect: "slide",
                        animationEffect: "zoom",
                        hideScrollbar: false,
                        buttons: [
                            "close"
                        ]
                    });
                });

                $("[data-lib-fancybox-open]").on("click", function(){
                    let id = $(this).data("lib-fancybox-open");
                    $(`[data-lib-fancybox="${id}"] .col:first-of-type .lib--fancybox-item`).trigger("click")
                })
            });
        }
    });
});

$.lui("lib_toggle", "[data-lib-toggle]", function(selector) {
    selector.on("change", function() {
        let elm = $(this);
        let id = elm.data("lib-toggle");

        if ($(this).prop("checked") === true) {
            $(`[data-lib-toggle-area="${id}"]`).removeClass("animate--fadeout").addClass("animate--fadein").stop(true).slideDown(300)
        } else {
            $(`[data-lib-toggle-area="${id}"]`).removeClass("animate--fadein").addClass("animate--fadeout").stop(true).slideUp(300)
        }
    });
});

$.lui("lib_intl", "[data-lib-intl]", function(selector) {
    bodyLoaded(function(){
        $.importStyle(cdnjs.intlTelInput_css);
        $.importScript(cdnjs.intlTelInput, function(){
            selector.each(function() {
                intlTelInput($(this)[0], {
                    nationalMode: false,
                    preferredCountries: ['cz', 'sk', 'pl'],
                    initialCountry: 'cz'
                });
            });

        });
    });
});

doc.on('change','[data-ajax-change]',function() {

    let checked = $(this).prop('checked') ? 1 : 0;
    let uri = $(this).attr('data-ajax-change');
    $.ajax({
        data: {checked: checked},
        url: uri,
        method: 'post',
        cache: false
    });
});

$.lui("lib_notification", "[data-lib-notification]", function(selector) {
    selector.each(function() {
        let elm = $(this);
        let data = elm.data("lib-notification");
        nl_lib_notification.open({
            autohide: true,
            state: data['state'],
            title: data['title'],
            text: data['text']
        });
    });
});

if (document.documentElement.classList.toString().match(/(android|ios|iphone|ipad)/) === null) {
    $.lui("lib_scrollbars", "[data-lib-scrollbars]", function (selector) {
        selector.each(function () {
            OverlayScrollbars($(this)[0], {
                overflowBehavior: {
                    x: "hidden",
                    y: "scroll"
                },
                scrollbars: {
                    autoHide: "move",
                    autoHideDelay: 800
                }
            });
        });
    });
}

doc.on('change', '[data-change-country]', function() {
    naja.makeRequest('POST', $(this).data('change-country'), {value: $(this).val()});
});

doc.on('click', '[data-headbar-active-toggle]', function () {
    $(this).closest('.elm_headbar_info').find('[data-headbar-active-toggle]').removeClass('state--active');
    $(this).addClass('state--active');
});

doc.on("click", `[data-doc-toggle="help"]`, function() {
    let elm = $(this), icon = elm.find(".icon");
    if(elm.hasClass("state--active")) {
        elm.removeClass("state--active");
        icon.removeClass("icon--help-fill");
        icon.addClass("icon--help-circle");
        html.removeClass("help--visible");
    }
    else {
        elm.addClass("state--active");
        icon.addClass("icon--help-fill");
        icon.removeClass("icon--help-circle");
        html.addClass("help--visible");
    }
});

doc.on('change', '[data-single-checkbox]', function () {
    $('[data-single-checkbox]').not(this).prop('checked', false);
});

doc.on('change', '[data-clear-on-unavailable]', function () {
    let val = $(this).val();

    if (val === 'UNAVAILABLE') {
        $('input[data-to-clear]:not([type="checkbox"])').val('');
        $('input[data-to-clear][type="checkbox"]').prop('checked', false);
    }
});

setTimeout(function () {
    location.href = '/login/out';
}, 2 * 60 * 60 * 1000); // logout after 2 hours on inactivity

/* not needed now
(function logActivity() {
    naja.makeRequest('POST', location.href, {do: 'logActivity'}, {unique: false});
    setTimeout(logActivity, 60000);
})();
*/