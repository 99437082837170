$.importScript(cdnjs.touchswipe, function() {
    $(".ssm-nav").lui_ssm();
});

if (localStorage.getItem("navCompact") === "true") {
    $(lui.refs.layout_nav).addClass("is--compact");
}

if (localStorage.getItem("subNavVisible") === "true") {
    $(lui.refs.layout_nav).addClass("is--sub");
    let text = $(lui.refs.layout_nav).find("[data-strings]");
    let text_strings = text.data("strings");
    if(typeof text_strings !== "undefined") {
        text.text(text_strings.less);
    }
}

doc.on("click", `[data-doc-click="toggleCompact"]`, function(){
    if (!$(lui.refs.layout_nav).hasClass("is--compact")) {
        localStorage.setItem("navCompact", "true");
        $(lui.refs.layout_nav).addClass("is--compact");
    } else {
        localStorage.removeItem("navCompact");
        $(lui.refs.layout_nav).removeClass("is--compact");
    }
});

$(lui.refs.layout_nav).on("click", `[data-toggle]`, function (e) {
    e.preventDefault();
    let el = $(this);
    let text = el.find("[data-strings]");
    let text_strings = text.data("strings");
    let sub = $(lui.refs.layout_nav).find(`[data-ref-toggle="${el.data("toggle")}"]`);
    if ($(lui.refs.layout_nav).hasClass("is--sub")) {
        sub.stop().slideUp(function () {
            localStorage.removeItem("subNavVisible");
            $(lui.refs.layout_nav).removeClass("is--sub");
            if(typeof text_strings !== "undefined") {
                text.text(text_strings.more);
            }
        });
    } else {
        sub.stop().slideDown(function () {
            localStorage.setItem("subNavVisible", "true");
            $(lui.refs.layout_nav).addClass("is--sub");
            if(typeof text_strings !== "undefined") {
                text.text(text_strings.less);
            }
        });
    }
});

window.addEventListener("resize", function(){
    if (win.width() > 960) {
        $(lui.refs.layout_nav).removeAttr("style")
    }
});