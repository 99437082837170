$.fn.lib_noui = function (options) {
    let refs = {
        this: $(this),
        slider: options.slider,
        elm_min: options.elm_min,
        elm_min_hidden: options.elm_min_hidden,
        elm_max: options.elm_max,
        elm_max_hidden: options.elm_max_hidden,
        unit: options.unit,
        currencyDecimals: 0,
        currencyPlacement: "s",
    };

    if (typeof refs.unit === "undefined") {
        refs.unit = "";
    }

    let autoNumeric_min;
    let autoNumeric_max;

    if (typeof refs.elm_min !== "undefined") {
        autoNumeric_min = new AutoNumeric(refs.elm_min[0], {
            digitGroupSeparator: ' ',
            decimalPlaces: parseInt(refs.currencyDecimals),
            minimumValue: "0",
            maximumValue: "9999999999",
            unformatOnHover: false,
            currencySymbol : refs.unit+' ',
            currencySymbolPlacement: refs.currencyPlacement,
            watchExternalChanges: true
        });
    }

    if (typeof refs.elm_max !== "undefined") {
        autoNumeric_max = new AutoNumeric(refs.elm_max[0], {
            digitGroupSeparator: ' ',
            decimalPlaces: parseInt(refs.currencyDecimals),
            minimumValue: "0",
            maximumValue: "9999999999",
            unformatOnHover: false,
            currencySymbol : refs.unit+' ',
            currencySymbolPlacement: refs.currencyPlacement,
            watchExternalChanges: true
        });
    }

    if (refs.slider.length) {
        let min_val;
        let max_val;
        if (typeof refs.elm_min !== "undefined") {
            min_val = autoNumeric_min.rawValue;
        }
        if (typeof refs.elm_max !== "undefined") {
            max_val = autoNumeric_max.rawValue;
        }

        let slider_min = refs.slider.data("min"),
            slider_max = refs.slider.data("max"),
            slider_step = refs.slider.data("step");

        if (refs.this.hasClass("is--type-single")) {
            noUiSlider.create(refs.slider[0], {
                start: max_val,
                step: slider_step,
                connect: [true,false],
                range: {
                    'min': slider_min,
                    'max': slider_max
                }
            });

            refs.elm_max.on('change', function () {
                if ($(this).val() !== "") {
                    refs.slider[0].noUiSlider.set(autoNumeric_max.rawValue);
                }
            });
        } else {
            noUiSlider.create(refs.slider[0], {
                start: [min_val, max_val],
                step: slider_step,
                connect: true,
                range: {
                    'min': slider_min,
                    'max': slider_max
                }
            });

            refs.elm_max.on('change', function () {
                if ($(this).val() !== "") {
                    refs.slider[0].noUiSlider.set([autoNumeric_min.rawValue, autoNumeric_max.rawValue]);
                }
            });

            refs.elm_min.on('change', function () {
                if ($(this).val() !== "") {
                    refs.slider[0].noUiSlider.set([autoNumeric_min.rawValue, autoNumeric_max.rawValue]);
                }
            });
        }

        refs.slider[0].noUiSlider.on('update', function (values, handle) {
            let value = values[handle];
            if (!handle) {
                if (typeof refs.elm_min_hidden !== "undefined") {
                    refs.elm_min_hidden[0].value = Math.round(value);
                }

                if (typeof refs.elm_min !== "undefined") {
                    autoNumeric_min.set(Math.round(value));
                }
            }
            if (handle || typeof refs.elm_min === "undefined") {
                if (typeof refs.elm_max_hidden !== "undefined") {
                    refs.elm_max_hidden[0].value = Math.round(value);
                }

                if (typeof refs.elm_max !== "undefined") {
                    autoNumeric_max.set(Math.round(value));
                }
            }
        });


        refs.slider[0].noUiSlider.on('change', function (values, handle) {
            if (!handle) {
                if (typeof refs.elm_min !== "undefined") {
                    if (autoNumeric_min.rawValue !== "") {
                        refs.elm_min.trigger('change');
                    }
                }
                if (typeof refs.elm_min_hidden !== "undefined") {
                    refs.elm_min_hidden.trigger('change');
                }
            }
            if (handle || typeof refs.elm_min === "undefined") {
                if (typeof refs.elm_max !== "undefined") {
                    if (autoNumeric_max.rawValue !== "") {
                        refs.elm_max.trigger('change');
                    }
                }
                if (typeof refs.elm_max_hidden !== "undefined") {
                    refs.elm_max_hidden.trigger('change');
                }
            }
        });
    }
};