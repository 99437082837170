$.lui("part",".part_profile_agenda", function(selector) {
    $.importScript(cdnjs.fullcalendar, function(){
        $.importScript(cdnjs.fullcalendar_daygrid, function(){
            $.importScript(cdnjs.fullcalendar_interaction, function(){
                $.importScript(cdnjs.fullcalendar_locales, function(){
                    $.importScript(cdnjs.dayjs, function(){
                        $.importScript(cdnjs.dayjs_locale.replace("cs.", `${lang}.`), function(){
                            dayjs.locale(lang);

                            let date = dayjs().date(1);

                            let calendar = new FullCalendar.Calendar(selector.find(`[data-ref="fullCalendar"]`)[0], {
                                // defaultDate: date.$d,
                                plugins: [ 'interaction', 'dayGrid' ],
                                timeZone: 'UTC',
                                height: "auto",
                                defaultView: selector.find(`[data-click^="fullCalendar--view"].state--active`).data('view'),
                                locale: lang,
                                eventSources: [
                                    {
                                        url: selector.find(`[data-ref="fullCalendar"]`).data("events"),
                                        extraParams: function() {
                                            return {
                                                type: $('[data-full-calendar-filter]').val(),
                                            };
                                        }
                                    }
                                ],
                                header: true,
                                weekends : true,
                                editable: false,
                                dateClick: function(info) {

                                },
                                columnHeaderHtml: function(date) {
                                    return `${dayjs(date).format('D. M.')} <span>${dayjs(date).format('dddd')}</span>`;
                                },
                                eventRender: function(event) {
                                    let title = $(event.el).find(".fc-title")[0];
                                    let checked = event.event._def.extendedProps["checked"] ? `<span class="icon icon--checkbox-filled" style="color: ${event.event._def.ui.backgroundColor}"></span>`: "";
                                    title.innerHTML = `${checked}<span class="text--overflow">${title.innerHTML}</span><button data-href="/marketplace/?back=%2F&ids=${event.event._def.extendedProps["ids"]}" class="grouped" data-no-swup>${event.event._def.extendedProps["grouped"]}</button>`;
                                },
                                eventClick: function(event) {
                                    if (event.jsEvent.target.tagName === 'BUTTON' && event.event._def.extendedProps["grouped"]) {
                                       location.href = event.jsEvent.target.dataset.href;
                                    } else {
                                        lui_dialog.call(event.event._def.extendedProps.url,lui_dialog_callback);
                                    }
                                },
                                datesRender: function(e) {
                                    // console.log(dayjs(e.view["dayTable"]["cells"][0][0].date).daysInMonth());
                                    // if (dayjs(e.view["dayTable"]["cells"][0][0].date).date(1).format("YYYY-MM-DD") !== dayjs().date(1).format("YYYY-MM-DD")) {
                                    //     //
                                    // }

                                    // let firstDay = dayjs(e.view["dayTable"]["cells"][0][0].date);
                                    //
                                    // console.log(date.format("YYYY-MM-DD"));
                                    //
                                    // if (date.format("YYYY-MM-DD") !== firstDay.format("YYYY-MM-DD")) {
                                    //     // calendar.setOption('duration', { days: firstDay.daysInMonth() });
                                    //     date = firstDay;
                                    //     // console.log(e,date.format("YYYY-MM-DD"), firstDay.daysInMonth());
                                    // }

                                    // date = dayjs(e.view["dayTable"]["cells"][0][0].date);
                                    //
                                    // console.log(dayjs(e.view["dayTable"]["cells"][0][0].date).format("YYYY-MM-DD") === dayjs().date(1).format("YYYY-MM-DD"));

                                    // setTimeout(function(){
                                    //     e.view.calendar.setOption('duration', { days: dayjs(e.view["dayTable"]["cells"][0][0].date).daysInMonth() });
                                    // },500);
                                }
                            });

                            calendar.render();


                            selector.on("click", `[data-click="fullCalendar--prev"]`, function() {
                                calendar.prev();
                                selector.find(`[data-ref="fullCalendarTitle"]`).text(dayjs(calendar.getDate()).format("MMMM YYYY"));
                            });

                            selector.on("click", `[data-click="fullCalendar--next"]`, function() {
                                calendar.next();
                                selector.find(`[data-ref="fullCalendarTitle"]`).text(dayjs(calendar.getDate()).format("MMMM YYYY"));
                            });

                            selector.on("click", `[data-click="fullCalendar--viewMonth"]`, function () {
                                naja.makeRequest('POST', location.href, {'do': 'setFullCalendarView', 'view': 'month'});
                                selector.find(`[data-click^="fullCalendar--view"]`).removeClass("state--active").filter(this).addClass("state--active");
                                calendar.changeView('dayGridMonth');
                            });

                            selector.on("click", `[data-click="fullCalendar--viewWeek"]`, function () {
                                naja.makeRequest('POST', location.href, {'do': 'setFullCalendarView', 'view': 'week'});
                                selector.find(`[data-click^="fullCalendar--view"]`).removeClass("state--active").filter(this).addClass("state--active");
                                calendar.changeView('dayGridWeek');
                            });

                            selector.on("click", `[data-click="fullCalendar--viewDay"]`, function () {
                                naja.makeRequest('POST', location.href, {'do': 'setFullCalendarView', 'view': 'day'});
                                selector.find(`[data-click^="fullCalendar--view"]`).removeClass("state--active").filter(this).addClass("state--active");
                                calendar.changeView('dayGridDay');
                            });

                            selector.on("change", '[data-full-calendar-filter]', function () {
                                calendar.refetchEvents();
                            });

                            //
                            // setTimeout(function(){
                            //     calendar.setOption('duration', { days: 7 });
                            // },5000);
                        });
                    });
                });
            });
        });
    });
});