$.lui("part", ".part_profile_graph", function(selector) {
    $.importScript(cdnjs.chartist, function () {
        $.importStyle(cdnjs.chartist_css);

        cssLoaded(function(){
            let chart = selector.find("[data-chart]");
            let labels = chart.data('labels');
            let series = chart.data('series');

            if (win.width() < 768) {
                labels.forEach(function(item, i){
                    labels[i] = labels[i].substring(0, 2);
                })
            }

            new Chartist.Bar(chart[0], {
                labels: labels,
                series: series,
            }, {
                fullWidth: true,
                axisX: {
                    labelOffset: {
                        y: 12
                    }
                },
                chartPadding: {
                    left: 0,
                    right: 32
                }
            });
        });
    })
});