doc.on('click', '[data-add-row]', function () {
    let defaultRow = $('[data-default-row]').clone();

    defaultRow.attr('data-default-row', null);
    defaultRow.find('[data-remove-row]').parent().show();
    defaultRow.find('[name]').each(function (i, item) {
        let itm = $(item);

        if (itm.attr('name') === 'location') {
            itm.val('Central');
        } else if (itm.attr('name') === 'stars') {
            itm.val(4);
        } else if (itm.attr('name') === 'label') {
            itm.val('serie');
        } else {
            itm.val('');
        }

        itm.attr('name', itm.attr('name') + 'Bulk[]');
    });

    $('[data-default-row]').after(defaultRow);

    luiLoad({"name": ["lib", "comp", "part"], "selector": '#dialog'});
});

doc.on('click', '[data-remove-row]', function () {
    $(this).closest('.part_item_buyer_bulk_action').parent().remove();
});