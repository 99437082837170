$.lui("comp", ".comp_marketplace_filter", function (selector) {

    selector.on("click", `[data-toggle="filterAdvanced"]`, function (e) {
        e.preventDefault();
        let filter = $(this).closest(`[data-ref="filterAdvanced"]`);
        let body = filter.find(".elm_item_body");
        if (filter.hasClass("state--active")) {
            filter.removeClass("state--active");
            body.stop().slideUp();
        } else {
            filter.addClass("state--active");
            body.stop().slideDown();
        }
    });

    selector.on("change", '[data-change="filterChange"]', function () {
        $('[data-filter-advanced]').hide();

        $('[data-change="filterChange"]:checked').each(function () {
            let val = parseInt($(this).val());
            let activeFilters = [];

            if (val === 1 || val === 2 || val === 3) {
                activeFilters.push('guide');
            } else if (val === 4) {
                activeFilters.push('coach');
            } else if (val === 5 || val === 6) {
                activeFilters.push('hotel');
            }

            activeFilters.forEach(function (item) {
                $(`[data-filter-advanced="${item}"]`).show();
            });
        });
    });
});