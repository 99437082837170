$.fn.lib_sortable = function () {
    $(this).each(function(){
        let elm = $(this);

        let sortable = new Sortable(this, {
            animation: 150,
            store: {
                get: function () {
                    return [];
                },
                set: function (sortable) {
                    let order = sortable.toArray();
                    naja.makeRequest('POST', elm.data('sortable-url'), {items: order});
                }
            }
        });
    });
};