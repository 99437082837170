let cdnjs = {
    "datepicker_lang": "https://cdn.jsdelivr.net/npm/air-datepicker@2.2.3/dist/js/i18n/datepicker.cs.min.js",
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.10.3/dist/headroom.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "autoNumeric": "https://cdn.jsdelivr.net/npm/autonumeric@4.5.10/dist/autoNumeric.min.js",
    "noui": "https://cdn.jsdelivr.net/npm/nouislider@14.1.1/distribute/nouislider.min.js",
    "autosize": "https://cdn.jsdelivr.net/npm/autosize@4.0.2/dist/autosize.min.js",
    "progressbar": "https://cdn.jsdelivr.net/npm/progressbar.js@1.1.0/dist/progressbar.min.js",
    "chartist": "https://cdn.jsdelivr.net/npm/chartist@0.11.4/dist/chartist.min.js",
    "chartist_css": "https://cdn.jsdelivr.net/npm/chartist@0.11.4/dist/chartist.min.css",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.2.1/dist/flickity.pkgd.min.js",
    "fancybox": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js",
    "fancybox_css": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css",
    "fullcalendar":"https://cdn.jsdelivr.net/npm/@fullcalendar/core@4.3.1/main.min.js",
    "fullcalendar_daygrid":"https://cdn.jsdelivr.net/npm/@fullcalendar/daygrid@4.3.0/main.min.js",
    "fullcalendar_interaction":"https://cdn.jsdelivr.net/npm/@fullcalendar/interaction@4.3.0/main.min.js",
    "fullcalendar_locales":"https://cdn.jsdelivr.net/npm/@fullcalendar/core@4.3.0/locales-all.min.js",
    "dayjs": "https://cdn.jsdelivr.net/npm/dayjs@1.8.19/dayjs.min.js",
    "dayjs_locale": "https://cdn.jsdelivr.net/npm/dayjs@1.8.19/locale/cs.js",
    "intlTelInput":"https://cdn.jsdelivr.net/npm/intl-tel-input@16.0.8/build/js/intlTelInput.min.js",
    "intlTelInput_css": "https://cdn.jsdelivr.net/npm/intl-tel-input@16.0.8/build/css/intlTelInput.min.css",
    "googlemaps": "https://maps.googleapis.com/maps/api/js?key={apikey}",
    "googlemaps_cluster": "https://cdn.jsdelivr.net/npm/@google/markerclusterer@1.0.3/src/markerclusterer.min.js"
};