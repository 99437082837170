doc.on("change", ".part_ui_input_file input", function(){
    let elm = $(this);

    if (!elm.filter("[multiple]").length) {
        elm.next("span").removeAttr("data-placeholder").text($(this).val().replace(/.*(\/|\\)/, ''));
    } else {
        let files = this.files;
        if (this.files.length > 1) {
            elm.next("span").removeAttr("data-placeholder").text(`${Object.keys(files).length} files`);
        } else {
            elm.next("span").removeAttr("data-placeholder").text($(this).val().replace(/.*(\/|\\)/, ''));
        }

    }
});
