$.lui("layout", lui.refs.layout_header, function(selector) {
    $.importScript(cdnjs.headroom, function(){
        new Headroom(selector[0], {
            offset: 52,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        }).init();
    });

    selector.on("click", `[data-toggle="search"]`, function(){

       if (!selector.hasClass("is--search-active")) {
           selector.addClass("is--search is--search-active");
           selector.find(`[data-ref="searchInput"]`).focus();
       } else {
           selector.removeClass("is--search");

           setTimeout(function(){
               selector.removeClass("is--search-active");
           },100)
       }
    });
});