$.fn.lib_flickity = function () {
    let selector = $(this);

    $.importScript(cdnjs.flickity, function() {
        cssLoaded(function () {
            selector.each(function(){
                let elm = $(this);
                let items = elm.children().length;
                let options = elm.data("lib-flickity");

                let opptions_all = {
                    groupCells: "25%",
                    cellAlign: "left",
                    setGallerySize: true,
                    autoPlay: options["autoplay"],
                    pageDots: true,
                    wrapAround: true,
                    contain: true,
                    adaptiveHeight: true,
                    pauseAutoPlayOnHover: true,
                    prevNextButtons: true,
                    percentPosition: false
                };

                let slider = $(this).flickity(opptions_all);

                slider.on( 'change.flickity', function( event, index ) {
                    elm.attr("data-count", `${index + 1} / ${items}`);
                });

                if (typeof options["destroyDevices"] !== "undefined") {
                    if (win.width() < 960) {
                        slider.flickity('destroy');
                    }

                    const mediaQuery = window.matchMedia('screen and (min-width : 60em)');
                    mediaQuery.onchange = e => {
                        if (win.width() > 960) {
                            slider.flickity(opptions_all);
                        } else {
                            slider.flickity('destroy');
                        }
                    }
                }
            });
        });
    });
};