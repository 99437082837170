$.lui("part", ".part_dialog_reviews", function(selector){
    selector.on("change", `[data-change="button"]`, function() {
        let elm = $(this);

        selector.find(`[data-change="button"]`).closest(".part_ui_btn").removeClass("state--active");
        elm.closest(".part_ui_btn").addClass("state--active");

        if (elm.prop("checked")) {
            selector.find(`[data-ref^="choicesTab"]`).removeClass("state--active");
            selector.find(`[data-ref="${elm.data('target')}"]`).addClass("state--active")
        }
    });

    selector.on("change", `[data-change="choices"]`, function() {
        if ($(this).prop("checked")) {
            $(this).closest(".elm_item").addClass("state--active");
        } else {
            $(this).closest(".elm_item").removeClass("state--active");
        }
    });
});