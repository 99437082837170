$.lui("part",".part_profile_stats_overview",(selector)=>{
    let refs = {
        tabs: selector.find(`[data-ref="tabs"]`),
    };
    refs.tabs.on("click","[data-action]",function(e){
        let elm = $(this);
        if(elm.hasClass("state--active")) return;
        elm.closest(refs.tabs).find(".state--active").removeClass("state--active");
        elm.addClass("state--active");
    });
});