doc.on("change", ".part_registration_item_c", function () {
    let elm = $(this);

    $(".part_registration_item_c").removeClass("state--active");

    if (elm.find("input").prop("checked")) {
        elm.addClass("state--active");
    }
});

doc.on("change", ".part_registration_item_t", function () {
    let elm = $(this);

    $(".part_registration_item_t").removeClass("state--active");

    if (elm.find("input").prop("checked")) {
        elm.addClass("state--active");
    }
});

doc.on("change", '.comp_registration [name="mainType"]', function () {
    let elm = $(this);

    $(".comp_registration .part_registration_item_c").removeClass("state--active");
    $('.comp_registration [data-supplier]').toggle(elm.val() === 'SUPPLIER');

    if (elm.val() === 'BUYER') {
        $('.comp_registration [data-client] input').click();
    } else {
        $('.comp_registration [data-client] input').prop('checked', false);
    }
});