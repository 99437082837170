(function() {
    if (typeof Swup !== "undefined" && document.getElementById("layout_header") !== null) {
        swup = new Swup({
            containers: ['#layout_main', '#layout_nav_inner'],
            animationSelector: '[class*="swup-transition-"]',
            cache: false
        });

        swup.on('clickLink', function () {
            $(".ssm-nav-visible").removeClass("ssm-nav-visible");
            $(".ssm-overlay").fadeOut(300);
            body.removeClass("body--no-scroll");
            if ($(".lib--dialog").length) {
                lui_dialog.close();
            }
            tippy.hideAll({ duration: 0 });
            $("#layout").removeClass("is--animate-login");
            html.removeClass("help--visible");

            localStorage.setItem("tabScrollPosition", $(".part_tabs").scrollLeft());
        });

        doc.on("click", "[data-href]", function(e){
            let elm = $(this);

            if (e.target.nodeName !== "A" && e.target.nodeName !== "BUTTON" &&  e.target.nodeName !== "INPUT" && !$(e.target).closest("button").length && !$(e.target).hasClass("part_ui_checkbox") && $(e.target).css("cursor") !== "default") {
                swup.loadPage({
                    url: elm.data("href"),
                    method: 'GET'
                });

                document.getElementById("layout").classList.remove("is--animate-login");
            }
        });

        document.addEventListener('swup:animationInDone', (event) => {
            if (typeof osScrollMain !== "undefined") {
                osScrollMain.scroll(0);
            } else {
                window.scrollTo(0, 0);
            }
        });

        swup.on('contentReplaced', function () {
            if (typeof Capacitor !== "undefined") {
                if (!$(".comp_messages_header").length) {
                    Capacitor.Plugins.StatusBar.setBackgroundColor({
                        "color": "#ffffff"
                    });

                    Capacitor.Plugins.StatusBar.setStyle({
                        "style": "LIGHT"
                    });
                }
            }

            luiLoad({"name": ["layout"]});

            let text = $(lui.refs.layout_nav).find("[data-strings]");
            let text_strings = text.data("strings");

            if(localStorage.getItem("subNavVisible") === "true") {
                text.text(text_strings.less);
            } else {
                text.text(text_strings.more);
            }

            $(".part_tabs").scrollLeft(localStorage.getItem("tabScrollPosition"));

            swup.options.containers.forEach((selector) => {
                luiLoad({"name": ["comp", "part", "lib"], "selector": selector});
            });

            document.body.classList.remove("body--preload");
            document.body.classList.add("body--loaded");

            naja.uiHandler.bindUI(document.body);
        });
    }
})();