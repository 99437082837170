let $ = jQuery,
    doc = $(document),
    win = $(window),
    html = $("html"),
    body = $("body"),
    lang = html.attr('lang');

let swup;
let osScrollMain;

window.lui = {
    layout: {},
    comp: {},
    part: {},
    lib: {},
    refs: {
        layout_header: "#layout_header",
        layout_main: "#layout_main",
        layout_aside: "#layout_aside",
        layout_nav: "#layout_nav",
        layout_navbar: "#layout_navbar",
        layout_toolbar: "#layout_toolbar"
    }
};

if ('serviceWorker' in navigator && location.protocol === "https:") {
    window.addEventListener('load', function() {
        if (!html.hasClass("no-sw")) {
            let swRegistration;

            navigator.serviceWorker.register('/sw.js').then(function(swReg){
                swRegistration = swReg;
            }).catch(function(e) {
                console.error('Error during service worker registration:', e);
            });

            let appInstalled = false;

            window.addEventListener('appinstalled', (evt) => {
                appInstalled = true;

                doc.on('contextmenu', function (e) {
                    if (e.target.nodeName !== "A" && !$(e.target).closest("a").length) {
                        e.preventDefault();
                        e.stopPropagation();
                    } else {
                        e.preventDefault();
                        e.stopPropagation();
                        console.log(e);
                    }
                });
            });

            if (appInstalled === false && localStorage.getItem("pwaState") === "accepted") {
                localStorage.removeItem("pwaState");
            }

            if (localStorage.getItem("pwaState") === null && document.documentElement.classList.toString().match(/(android|ios|iphone|ipad)/) === null) {
                let deferredPrompt;

                window.addEventListener('beforeinstallprompt', (e) => {
                    e.preventDefault();

                    setTimeout(function () {
                        body.append(`
                            <div class="part_prompt state--active is--animate">
                                <div class="wrp_part_text">
                                    <div class="part_ui_wsw">
                                        <p>Přidejte si Group Market Place jako aplikaci do vašeho zařízení a&nbsp;mějte ji vždy při ruce.</p>
                                    </div>
                                </div>
                                <div class="wrp_part_btn row--flex row--h-8">
                                    <div class="col">
                                        <button class="part_ui_btn" aria-label="Instalovat jako aplikaci" data-pwa-approve><span>Instalovat jako aplikaci</span></button>
                                    </div>
                                    <div class="col">
                                        <button class="part_ui_btn type--outline display--auto" aria-label="Neinstalovat aplikaci" data-pwa-disable><span>Ne děkuji</span></button>
                                    </div>
                                </div>
                            </div>
                        `);

                        deferredPrompt = e;
                    },1500);
                });

                doc.on("click", "[data-pwa-approve]", function(){
                    let prompt = $(".part_prompt");
                    deferredPrompt.prompt();

                    deferredPrompt.userChoice.then((choiceResult) => {
                        if (choiceResult.outcome === 'accepted') {

                            prompt.removeClass("is--animate");
                            localStorage.setItem("pwaState", "accepted");

                            setTimeout(function () {
                                prompt.remove();
                            },1000);

                            deferredPrompt = null;
                        }
                    });
                });

                doc.on("click", "[data-pwa-disable]", function(){
                    let prompt = $(".part_prompt");

                    prompt.removeClass("is--animate");
                    localStorage.setItem("pwaState", "disabled");

                    setTimeout(function () {
                        prompt.remove();
                    },1000);
                });
            }
        } else {
            navigator.serviceWorker.getRegistrations().then(function(registrations) {
                if (registrations.length > 0) {
                    for(let registration of registrations) {
                        registration.unregister()
                    }
                }
            });
        }
    });
}

(function appInit() {
    if (!document.documentElement.classList.contains("is-mobile")) {
        if (typeof OverlayScrollbars !== "undefined") {
            osScrollMain = OverlayScrollbars(document.querySelectorAll("body"), {
                overflowBehavior : {
                    x : "hidden",
                    y : "scroll"
                },
                scrollbars : {
                    autoHide         : "move",
                    autoHideDelay    : 800
                }
            });
        }
    }

    if (document.body.classList.contains("body--preload")) {
        cssLoaded(function(){
            document.body.classList.remove("body--preload");
            setTimeout(function () {
                document.body.classList.add("body--loaded");
            }, 300);
        });
    }

    if (typeof WebFontConfig === "undefined" && document.querySelector("#layout_manifest") !== null) {
        if (typeof document.fonts !== "undefined") {
            document.fonts.ready.then(function () {
                document.documentElement.classList.add("wf-active");
            });
        } else {
            document.documentElement.classList.add("wf-active");
        }
    }

    if (typeof Capacitor !== "undefined") {

        console.log('capacitor init');

        Capacitor.Plugins.SplashScreen.hide();

        Capacitor.Plugins.PushNotifications.register();

        Capacitor.Plugins.PushNotifications.addListener('registration', (token) => {

            console.log(token.value);

            let isRegisteredAttribute = document.querySelector("[data-device-registered]");

            if(document.querySelector("[data-logged-in]") !== null && (!isRegisteredAttribute || (isRegisteredAttribute && document.querySelector("[data-device-registered]").getAttribute("data-device-registered") !== token.value))) {
                Capacitor.Plugins.Device.getInfo().then(function(data) {

                    let dataToSend = data;
                    dataToSend.token = token.value;

                    $.ajax({
                        data: dataToSend,
                        url: '/device/?do=registerDevice',
                        method: 'post',
                        cache: false
                    });
                });
            }
        });
        
        doc.on("click", `a[download], a[target="_blank"]`, function(e) {
            e.preventDefault();
            let elm = $(this);
            Capacitor.Browser.open({ url: elm.attr("href") });
        });

        Capacitor.Plugins.PushNotifications.addListener('registrationError', (error) => {
            console.error('Error on registration: ' + JSON.stringify(error));
        });

        Capacitor.Plugins.PushNotifications.addListener('pushNotificationReceived', (notification) => {
            console.log('Push received: ' + JSON.stringify(notification));
        });

        Capacitor.Plugins.PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
            console.log('Push action performed: ' + JSON.stringify(notification));
            window.location.href = notification.data.url;
        });

        if (typeof Capacitor !== "undefined" && typeof Capacitor.Plugins.NavigationBar !== "undefined") {
            if (!$(".comp_login").length && !$(".comp_registration").length) {
                Capacitor.Plugins.NavigationBar.setBackgroundColor({color: '#ffffff'}).catch(function(error) {
                    console.log(error);
                });
            }
        }
    }
})();
