$.lui("part", ".part_profile_stats", function(selector){
    $.importScript(cdnjs.progressbar, function(){
        let demandsCircle = selector.find(`[data-ref="demandsCircle"]`);
        let options = demandsCircle.data("options");

        new ProgressBar.Circle(demandsCircle[0], {
            strokeWidth: 4,
            trailWidth: 4,
            trailColor: options["trailColor"],
            color: options["color"],
            easing: 'easeInOut',
            duration: 1400
        }).animate(options["value"]);
    });
});