$.lui("part",".part_profile_stats_destinations", (selector)=>{
    let refs = {
        chart: selector.find(`[data-ref="destinationsChart"]`)
    };

    $.importStyle(cdnjs.chartist_css);
    $.importScript(cdnjs.chartist,()=>{
        new Chartist.Pie(refs.chart[0], {
            series: refs.chart.data("options")["series"]
        }, {
            donut: true,
            donutWidth: 16,
            donutSolid: false,
            startAngle: 100,
            showLabel: false
        }).on("created",function () {
            let svg = refs.chart.find("svg");
            let series = svg.find(".ct-series");
            svg.html(series.toArray().reverse());
        });
    });
});