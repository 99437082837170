$.fn.lui_ripple = function () {
    $(this).each(function(){
        if (!$(this).filter("[data-lib-ripple]").length) {
            $(this).attr("data-lib-ripple", "");
        }
    });
};

doc.on("click", "[data-lib-ripple]", function(event){
    let surface = $(this);

    function fn_rippleInner(container) {
        if (container.children(".lib--ripple-inner").length === 0) {
            container.append("<div class='lib--ripple-inner'></div>");
        }

        let ink = container.children(".lib--ripple-inner");

        ink.removeClass("animated");

        if (!ink.height() && !ink.width()) {
            let d = Math.max(surface.outerWidth(), surface.outerHeight());
            ink.css({height: d, width: d});
        }

        let x, y;

        if (container === body) {
            x = event.clientX - container.offset().left - (ink.width() / 2);
            y = event.clientY - container.offset().top - (ink.height() / 2);
        } else {
            x = event.pageX - container.offset().left - (ink.width() / 2);
            y = event.pageY - container.offset().top - (ink.height() / 2);
        }

        ink.css({
            top: y + 'px',
            left: x + 'px',
        }).addClass("animated");
    }

    if (surface.data("lib-ripple") === "body") {
        fn_rippleInner(body);
    } else {
        fn_rippleInner(surface);
    }
});