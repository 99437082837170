function fn_lib_dialog() {
    const dialog = $("[data-lib-dialog]");

    function fn_lib_dialog_show(data, callback) {
        if (!doc.find(".lib--dialog").length) {
            body.append('<div class="lib--dialog" id="snippet--dialog">');
        } else {
            doc.find(".lib--dialog").empty();
        }

        doc.find(".lib--dialog").append(data).fadeIn(300);
        doc.find(".lib--dialog .part_dialog").focus();

        if (doc.find(".lib--dialog > .part_dialog").outerHeight(true) > win.height()) {
            let offset = window.innerWidth - document.body.clientWidth;
            body.css("padding-right", offset).addClass("body--no-scroll");
            $("#layout_header").css("right", offset);
        }

        if(typeof naja !== 'undefined') {
            naja.uiHandler.bindUI(document.body);
        }

        if (callback) {
            callback();
        }

        doc.find(".lib--dialog").on("mousedown", function(e) {
            if ($(e.target).hasClass("lib--dialog")) {
                doc.one("mouseup",function () {
                    fn_lib_dialog_hide();
                });
            }
        });
    }

    function fn_lib_dialog_hide(callback) {
        body.removeClass("overflow--no-scroll");
        doc.find(".lib--dialog").fadeOut(300);

        setTimeout(function () {
            body.removeClass("body--no-scroll").css("padding-right","");
            $("#layout_header").css("right","");

            doc.find(".lib--dialog").remove();
            if (callback) {
                callback();
            }
        }, 500);
    }

    function fn_lib_dialog_init(callback) {
        doc.on('click', '[data-lib-dialog]', function (e) {
            e.preventDefault();
            let elm = $(this);

            if (typeof naja !== 'undefined') {
                naja.makeRequest('GET', elm.data('lib-dialog')).then(function (data) {
                    fn_lib_dialog_show(data.snippets['snippet--dialog'], callback);
                });
            } else {
                $.ajax({
                    dataType: "json",
                    data: {ajax: +new Date},
                    url: elm.data('lib-dialog')
                }).done(function(data) {
                    fn_lib_dialog_show(data.dialog, callback);
                });
            }
        });

        if (body.filter("[data-lib-dialog-open]").length) {
            if (typeof naja !== 'undefined') {
                naja.makeRequest('GET', body.data('lib-dialog-open')).then(function (data) {
                    // fn_lib_dialog_show(data.snippets['snippet--dialog'], callback);
                });
            } else {
                $.ajax({
                    dataType: "json",
                    data: {ajax: +new Date},
                    url: body.data('lib-dialog-open')
                }).done(function(data) {
                    bodyLoaded(function(){
                        fn_lib_dialog_show(data.dialog, callback);
                    });
                });
            }
        }

        doc.on("click", "[data-lib-dialog-close]", function (e) {
            e.preventDefault();
            fn_lib_dialog_hide();
        });
    }

    function fn_lib_dialog_call(url, callback) {
        if (typeof naja !== 'undefined') {
            naja.makeRequest('GET', url).then(function (data) {
                fn_lib_dialog_show(data.snippets['snippet--dialog'], callback);
            });
        } else {
            $.ajax({
                dataType: "json",
                data: {ajax: +new Date},
                url: url
            }).done(function(data) {
                fn_lib_dialog_show(data.dialog, callback);
            });
        }
    }

    return {
        open: fn_lib_dialog_show,
        close: fn_lib_dialog_hide,
        init: fn_lib_dialog_init,
        call: fn_lib_dialog_call
    };
}

const lui_dialog = fn_lib_dialog();

const lui_dialog_callback = function() {
    luiLoad({"name": ["lib", "part"], "selector": ".lib--dialog"});
};