let messageCheckInterval = null;

$.lui("comp", ".comp_messages_body", function (selector) {
    if ($(selector).find('[data-live-area]').length) {
        if (messageCheckInterval !== null) {
            clearInterval(messageCheckInterval);
        }

        messageCheckInterval = setInterval(function () {
            let elm = $('[data-live-area]');

            if (!elm.length) {
                clearInterval(messageCheckInterval);
            } else {
                let scrollBottom = false;

                if ((window.innerHeight + document.querySelector(".os-viewport").scrollTop) >= $('#layout')[0].offsetHeight) {
                    scrollBottom = true;
                }

                naja.makeRequest('POST', elm.data('live-area'), null, {unique: false, scrollBottom: scrollBottom});
            }
        }, 10000);
    }

    setTimeout(function () {
        lui_scrollTo(document.querySelector("#layout").offsetHeight);
    }, 100);
});
