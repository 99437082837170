window.lui_references_responses = {};

$.lui("comp",".comp_marketplace_map",(selector)=>{
    let map_elm = selector.find("[data-map]");
    if(map_elm.length) {
        let options = map_elm.data("map");

        $.importScript(cdnjs.googlemaps.replace("{apikey}",options["apikey"]),()=>{
            $.importScript(cdnjs.googlemaps_cluster, ()=>{
                let coords = options["center"].split(/,\s/);
                let bounds;
                let markers_temp = [];
                let icon_template = selector.find("#map-marker").html();
                let info_template = selector.find("#map-info").html();
                let map = new google.maps.Map(map_elm[0],{
                    center: new google.maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1])),
                    zoom: options.zoom,
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false
                });

                let infoWindow = new google.maps.InfoWindow({
                    content: "",
                });

                function getMakers(url, callback) {
                    if (typeof lui_references_responses[url] === "undefined") {
                        $.ajax({
                            url: url,
                            dataType: "json",
                        }).done(function (payload) {
                            window.lui_references_responses[url] = payload;
                            callback(payload);
                        });
                    }
                    else {
                        callback(window.lui_references_responses[url]);
                    }
                }

                function generateMarker(item) {
                    let title = item["title"];
                    let point = new google.maps.LatLng(parseFloat(item["latitude"]), parseFloat(item["longitude"]));
                    let svg = icon_template.replace("{color}",item["color"]);
                    let content = info_template
                        .replace("{title}",title)
                        .replace("{image}",item["image"])
                        .replace("{rating}",item["rating"])
                        .replace("{availability}",item["availability"])
                        .replace("{price}",item["price"])
                        .replace("#a1a1a1",item["color"])
                        .replace("{url}",item["url"]);

                    let icon = {
                        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg),
                        scaledSize: new google.maps.Size(38, 46),
                        anchor: new google.maps.Point(19, 46)
                    };
                    let marker = new google.maps.Marker({
                        title: title,
                        map: map,
                        position: point,
                        icon: icon,
                    });
                    bounds.extend(point);
                    markers_temp.push(marker);

                    marker.addListener('click', function() {
                        infoWindow.close();
                        infoWindow.setContent(content);
                        infoWindow.open(map,marker);
                    });

                    marker.addListener('mouseover', function() {
                        infoWindow.close();
                        infoWindow.setContent(content);
                        infoWindow.open(map,marker);
                    });
                }

                map.addListener("click",function () {
                    infoWindow.close();
                });

                getMakers(map_elm.data("markers"),(payload)=>{
                    bounds = new google.maps.LatLngBounds();
                    for (let item of payload) {
                        generateMarker(item);
                    }
                    let markerCluster = new MarkerClusterer(map, markers_temp, {imagePath: '/assets/img/markers/m'});

                    if (payload.length > 0) {
                        map.fitBounds(bounds);
                    }
                });
            });
        });
    }
});