doc.on("change", ".part_ui_upload input", function(){
    let elm = $(this);
    let file = elm[0].files[0];
    let reader = new FileReader();

    reader.onload = function(e)  {
        elm.prev().html(`<img src="${e.target.result}" />`);
        elm.parent().addClass("state--active");
    };

    reader.readAsDataURL(file);
});