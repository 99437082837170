$.lui("part", ".part_dialog_notes", function(selector){
    selector.on("click", `[data-click="addNote"]`, function() {
        let color = selector.find(`[data-ref="colors"]`).find("input:checked").val();
        let value = selector.find(`[data-ref="input"]`).val();

        if (value.length > 0) {
            selector.find(`[data-ref="tags"]`).append(`
                <div class="col">
                    <div class="part_ui_tag type--fade" style="color: ${color}" data-color="${color}">
                        <span class="icon-r icon--close" data-click="removeNote"></span>
                        <span>${value}</span>
                    </div>
                </div>
            `);
        }

        fillInput()
    });

    selector.on("click", `[data-click="removeNote"]`, function() {
        $(this).closest(".col").remove();

        fillInput();
    });

    selector.on("click", `[data-click="addNoteSubmit"]`, function(e) {
        e.preventDefault();
        $('[data-click="addNote"]').click();
        naja.uiHandler.submitForm(this.form);
    });

    function fillInput() {
        let notes = [];

        selector.find(`[data-ref="tags"] .part_ui_tag`).each(function () {
            let color = $(this).data('color');
            let text = $(this).text().trim();

            notes.push(color + '|' + text);
        });

        selector.find('[name="notes"]').val(notes.join('\n'));
    }

    fillInput();
});