$.fn.lui_select = function () {
    $(this).each(function(){
        let elm = $(this).children("select");
        let search = typeof elm.attr("data-search") !== "undefined";
        let addable = typeof elm.attr("data-addable") !== "undefined";
        let multiple = typeof elm.attr("multiple") === "undefined";
        let data = [];
        // let mobile = document.documentElement.classList.toString().match(/(android|ios|iphone|ipad)/) === null;
        let mobile = true;

        if (elm.parent().find("template").length) {

            elm.children().each(function(){
                let value = $(this).val();
                let text = $(this).text();
                let attributes = [];
                let placeholder = $(this)[0].hasAttribute("data-placeholder");
                let template = elm.parent().find("template")[0].innerHTML;

                $.each($(this)[0].attributes, function() {
                    if (this.specified && this.name.match("data-") && !this.name.match("data-placeholder")) {
                        attributes.push({
                            "name": this.name,
                            "value": this.value
                        });
                    }
                });

                attributes.forEach(function(key){
                    template = template.replace(`'{${key.name}}'`, key.value);
                    template = template.replace(`{${key.name}}`, key.value);
                });

                if (placeholder) {
                    data.push({
                        "text": text,
                        "placeholder": placeholder
                    });
                } else {
                    data.push({
                        "innerHTML": template.replace("{slot}", text),
                        "text": text,
                        "value": value
                    });
                }

            })
        }

        if (data.length === 0) {
            data = null;
        }

        if (mobile || multiple === false) {
            elm.parent().addClass("lib--slimselect");

            if (!mobile) {
                search = false;
            }

            if (addable) {
                search = true;
            }

            search = true;

            new SlimSelect({
                select: elm[0],
                showSearch: search,
                searchPlaceholder: " ",
                searchText: "Žádné výsledky",
                placeholder: "Vyberte",
                closeOnSelect: multiple,
                allowDeselect: false,
                allowDeselectOption: !multiple,
                data: data,
                addable: addable ? function (value) {
                    return value;
                } : false
            });
        } else {
            if (elm.find("[data-placeholder]").length) {
                elm.parent().addClass("state--placeholder")
            }
        }
    });
};