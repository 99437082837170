$.lui("comp", ".comp_messages_header", function(selector){
    if (document.documentElement.classList.contains("is-mobile")) {
        win.off("scroll.comp_messages_header");
        win.on("scroll.comp_messages_header", function(){
            if (html.scrollTop() > selector.height() + 96) {
                selector.addClass("is--scroll");
            } else {
                selector.removeClass("is--scroll");
            }
        });
    }

    if (typeof Capacitor !== "undefined") {
        let backgroundColor;
        
        if (selector.data("options")["backgroundColor"] === "") {
            backgroundColor = "#ffffff"
        } else {
            backgroundColor = selector.data("options")["backgroundColor"]
        }
        
        Capacitor.Plugins.StatusBar.setBackgroundColor({
            "color": backgroundColor
        });

        Capacitor.Plugins.StatusBar.setStyle({
            "style": "DARK"
        });
    }
});
