function fn_lib_notification() {
    
    function fn_lib_notification_show(data) {
        let date = new Date();
        let id = doc.find(".lib--notification .part_ui_notification").length;
        let uid = date.getTime() + date.getMilliseconds() + id;

        let tpl = '<div class="part_ui_notification state--'+ data.state +'" data-id="'+uid+'"><button class="elm_close icon--close" data-notification-close></button>' +
            '<div class="wrp_part_body row--flex"><div class="col"><strong class="elm_title">'+ data.title +'</strong></div><div class="col"><span class="elm_text">'+ data.text +'</span></div></div>' +
            '</div>';

        // doc.find(".lib--notification .part_ui_notification").remove();
        if (!doc.find(".lib--notification").length) {
            body.append('<div class="lib--notification"></div>');
        }

        doc.find(".lib--notification").append(tpl);

        setTimeout(function(){
            doc.find(".lib--notification .part_ui_notification").filter('[data-id="'+uid+'"]').addClass("is--animate-in").show();
        },500*(id/2));

        if (data.autohide) {
            setTimeout(function(){
                fn_lib_notification_hide(uid);
            },14000*((id+1)/2));
        }
    }

    function fn_lib_notification_hide(id) {
        doc.find(".lib--notification .part_ui_notification").filter('[data-id="'+id+'"]').addClass("is--animate-out");
        setTimeout(function(){
            doc.find(".lib--notification .part_ui_notification").filter('[data-id="'+id+'"]').slideUp(300);
            setTimeout(function () {
                doc.find(".lib--notification .part_ui_notification").filter('[data-id="'+id+'"]').remove();
                if (doc.find(".lib--notification .part_ui_notification").length < 1) {
                    doc.find(".lib--notification").remove();
                }
            }, 300);
        },400);
    }

    function fn_lib_notification_hide_all() {
        doc.find(".lib--notification .part_ui_notification").addClass("is--animate-out");
        setTimeout(function(){
            doc.find(".lib--notification .part_ui_notification").slideUp(300);
            setTimeout(function () {
                doc.find(".lib--notification .part_ui_notification").remove();
                if (doc.find(".lib--notification .part_ui_notification").length < 1) {
                    doc.find(".lib--notification").remove();
                }
            }, 300);
        },400);
    }

    doc.on("click", "[data-notification-close]", function(){
        let id = $(this).closest(".part_ui_notification").data("id");
        fn_lib_notification_hide(id);
    });

    return {
        open: fn_lib_notification_show,
        hideAll: fn_lib_notification_hide_all
    };
}
let nl_lib_notification = fn_lib_notification();