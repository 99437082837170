$.lui("part", ".part_tabs", function(selector) {
    selector.on("click", ".elm_item", function(){
        let inner = $(this).closest(".part_tabs"),
            inner_col = inner.children(".col");

        inner.animate({scrollLeft: fn_col_width(inner.find(".state--active").parent().index())}, 500);

        function fn_col_width(index) {
            let width = 0;
            inner_col.each(function(i){
                if (i < index) {
                    width = width + $(this).outerWidth();
                }
            });

            return width;
        }
    });

    selector.each(function() {
        let inner = $(this),
            inner_col = inner.children(".col");

        // inner.scrollLeft(fn_col_width(inner.find(".state--active").parent().index()));
        setTimeout(function(){
            inner.animate({scrollLeft: fn_col_width(inner.find(".state--active").parent().index())}, 500);
        },150);

        function fn_col_width(index) {
            let width = 0;
            inner_col.each(function(i){
                if (i < index) {
                    width = width + $(this).outerWidth();
                }
            });

            return width;
        }
    });
});